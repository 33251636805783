import styled from "styled-components";
import { API_CONNECTION, CLR_BG3, CLR_LT1, CLR_PR1 } from "../app/constants";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${CLR_BG3};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  overflow-x: auto;
`;

const Button = styled.button`
  width: 200px;
  min-width: fit-content;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: ${CLR_PR1};
  color: white;
  font-size: 1rem;
  border-radius: 15px;
  font-weight: 600;
  margin-top: 11px;
`;
const Error = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: red;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "2px solid #002347",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};
const ExportOptionsModal = ({ submitChoice }) => {
  const [exportModal, setExportModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    ['urpay-web', 'urpay-app', 'stc-web', 'stc-app', 'card-web', 'card-app', 'apple-web', 'apple-app', 'approved']
    );
  const [error, setError] = useState("");
  const openModal = () => {
    setSelectedOptions(['urpay-web', 'urpay-app', 'stc-web', 'stc-app', 'card-web', 'card-app', 'apple-web', 'apple-app', 'approved'])
    setExportModal(true);
    setError(false)
  }

  const options = [
    { name: "Urpay (Web)", value: "urpay-web" },
    { name: "Urpay (App)", value: "urpay-app" },
    { name: "STC Pay (Web)", value: "stc-web" },
    { name: "STC Pay (App)", value: "stc-app" },
    { name: "CARD (Web)", value: "card-web" },
    { name: "CARD (App)", value: "card-app" },
    { name: "Apple Pay (Web)", value: "apple-web" },
    { name: "Apple Pay (App)", value: "apple-app" },
    { name: "Approved Transactions", value: "approved" },
    { name: "Declined Transactions", value: "declined" },
  ];
  const handleSelection = (choiceValue, choiceCheckStatus) => {
    if (choiceCheckStatus) {
     let newArr = [...selectedOptions, choiceValue]
     
      setSelectedOptions([...new Set(newArr)]);
    } else {
      const selectedOptionsAfterRemoval = selectedOptions.filter(
        (item) => item !== choiceValue
      );
      setSelectedOptions([...new Set(selectedOptionsAfterRemoval)]);
    }
  };

  const handleSubmitRequest = () => {
    const isPaymentStatusIncluded = selectedOptions.some(
      (item) => item === "approved" || item === "declined"
    );
    if (!isPaymentStatusIncluded) {
      setError("* Payment Status Has To Be Included");
      return;
    }
    const optionsWithoutPaymentStatus = selectedOptions.filter(
      (item) => item !== "approved" && item !== "declined"
    );
    if (optionsWithoutPaymentStatus.length === 0) {
      setError("* Reports Options has to be selected");
      return;
    }

    submitChoice("custome", "custome", selectedOptions);
    setExportModal(false)
  };
  return (
    <Container>
      <Button onClick={openModal}>Export</Button>
      <Modal
        open={exportModal}
        onClose={() => setExportModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {error && <Error>{error}</Error>}

          <FormGroup>
            {options.map((item) => {
              return (
                <FormControlLabel
                  key={item.value}
                  control={<Checkbox defaultChecked={selectedOptions.includes(item.value)} />}
                  label={item.name}
                  onChange={(e) =>
                    handleSelection(item.value, e.target.checked)
                  }
                />
              );
            })}
          </FormGroup>
          <Button onClick={handleSubmitRequest}>
            Export
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ExportOptionsModal;
